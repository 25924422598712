// Background colours
$accentColour: #4b89d1 !default;

// Text colours
$linkColour: #4b89d1 !default;

// Striped Table Class
//table-striped: 

.table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: #ffb;
  }
}

.table-bordered {
  border: 1px solid #551;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid #885;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}
